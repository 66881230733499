
import Header from "./header";
import Footer from "./footer";
import Projects from "./projects";

const projectsPage = () => {
    return(
        <>
            <Header />
            <Projects />
            <Footer />
        </>
    )
}

export default projectsPage;