
import Header from "./header";
import Main from "./main";
import Footer from "./footer";

const HomePage = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    )
}

export default HomePage;